;$(function() {
    var supportHome = {
        init: function(){
            var self = this;

            //self.settings();
            //self.bindEvents();
            //self.customSelectbox();
            self.iconMenuSlider();
            self.productCarousel();
            self.noticeEvents();
        },
        noticeEvents: function(){
            var $slide = $('.home-notice .notice-slider');
            $slide.find('.slick-arrow').hide();

        },
        iconMenuSlider: function() {
            function iconMenuSize(){
                if(window.innerWidth > 780) {
                    $('.ui_carousel_icon_slider').find('.slide-controls').hide();
                    $('.ui_carousel_icon_slider').vcCarousel('destroy');
                } else {
                    //console.log("tablet 이하");
                    $('.ui_carousel_icon_slider').vcCarousel({
                        infinite: false,
                        pauseOnHover: false,
                        pauseOnFocus: false,
                        swipeToSlide: true,
                        slidesToShow: 5,
                        slidesToScroll: 5,
                        arrows: true,
                        touchThreshold: 100,
                        cssEase: 'cubic-bezier(0.33, 1, 0.68, 1)',
                        speed: 150
                    });
                    $('.ui_carousel_icon_slider').find('.slide-controls').show();
                }
            }

            $(window).on('resize', function(){
                iconMenuSize();
            });

            iconMenuSize();

        },
        productCarousel: function() {
            var $section = $(".banner-promo");
            var $prodctWrap = $section.find(".promo-inner");
            var slideOpt = {
                infinite: false,
                autoplay: false,
                pauseOnHover: false,
                pauseOnFocus: false,
                swipeToSlide: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                touchThreshold: 100,
                cssEase: "cubic-bezier(0.33, 1, 0.68, 1)",
                speed: 150,
            };

            function prdCarouselSize(){
                $prodctWrap.not(".slick-initialized").slick(slideOpt);
                $prodctWrap.each(function(){
                    var prdLeng = $(this).find('.promo-item').length;

                    //상품 갯수가 5개 이상일 경우에 carousel 호출
                    if( prdLeng > 3){
                        $(this).not(".slick-initialized").slick(slideOpt);
                    }else{
                        $(this).filter(".slick-initialized").slick("unslick");
                    }
                })
            }
            //resize carousel 호출
            $(window).on('resize', function(){
                prdCarouselSize();
            });

            prdCarouselSize();

            ///
            sliderSet("pc");
            function sliderSet(type) {
                
                var infoSliderNum = $(".info-area").find(".slide-item").length;
                var infoOpt = {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    vertical: true,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    pauseOnHover: true,
                    pauseOnFocus: true,
                };
                
                
                $(".info-area").on("init", function (event, slick) {
                    var $page = $(".info-section .slide-page");
                    var $current = $page.find(".current");
                    var $count = $page.find(".count");
    
                    $current.text(slick.currentSlide + 1);
                    $count.text(slick.$slides.length);
                });
                if ($(".info-area").hasClass("slick-slider")) {
                    $(".info-area").not(".slick-initialized").slick(infoOpt);
                } else {
                    if (infoSliderNum == 0) {
                        $(".info-section").hide();
                    } else if (infoSliderNum == 1) {
                        $(".info-area-wrap").addClass("info-solo");
                    } else if (infoSliderNum > 1) {
                        $(".info-area").slick(infoOpt);
                    }
                }
                $(".info-area").on("beforeChange", function (event, slick, currentSlide, nextSlide) {
                    var $page = $(".info-section .slide-page");
                    var $current = $page.find(".current");
    
                    $current.text(nextSlide + 1);
                });
    
                $(".info-area").on("click", ".slick-arrow", function () {
                    if (infoOpt.autoplay && $(".info-area-wrap").find(".btn-info-play.pause").length > 0) {
                        $(".info-area").slick("slickPause");
                        setTimeout(function () {
                            $(".info-area").slick("slickPlay");
                        }, 100);
                    } else {
                        $(".info-area").slick("slickPause");
                    }
                });
            }
    
            // 재생/멈춤 클릭이벤트 위치 수정
            $(".btn-info-play").on("click", function () {
                if ($(this).hasClass("pause")) {
                    $(this).removeClass("pause");
                    $(".info-area").slick("slickPause");
                    $(".btn-info-play span").text("재생");
                } else {
                    $(this).addClass("pause");
                    $(".info-area").slick("slickPlay");
                    $(".btn-info-play span").text("멈춤");
                }
            });
            ///
        }
    };

    supportHome.init();
});

//고객지원 메인 상단 상품 셀렉트박스 커스텀
$(window).on('load', function () {
    function proSelButtomCustom(){
        
        var selectOption = $('.csHome select').vcSelectbox('selectedOption');
        var txtData = $(selectOption).text();
        var flagData = $(selectOption).data('flag');
        var codeData = $(selectOption).data('code');
        var optCnt = $('.csHome select option').length;
        var selectedIdx = $('.csHome select option:selected').index();

        if(flagData) var flagWrap = '<div class="flag-wrap bg-type"><span class="flag">'+flagData+'</span></div>';
        else var flagWrap = '';

        var selectWrap = $('.sort-select-wrap.csHome .ui-selectbox-wrap');
        selectWrap.find(".ui-select-button").removeClass('single');
        
        if(optCnt == 1) {
            var selectView = 
            '<span class="ui-select-text">'+txtData+'</span>'+
            '<div class="info">'+flagWrap+'<span class="code">'+codeData+'</span></div>';
            
            selectWrap.find(".ui-select-button").addClass('single');
        }
        else {
            var selectView = 
            '<span class="ui-select-text">'+txtData+'</span>'+
            '<span class="blind">선택됨</span><span class="ico"></span>'+
            '<div class="info">'+flagWrap+'<span class="code">'+codeData+'</span></div>';
            selectWrap.find(".ui-select-button").removeClass('single');
        }
        selectWrap.find(".ui-select-button").empty().append(selectView);

        selectWrap.find('.ui-selectbox-list li').removeClass('on').eq(selectedIdx).addClass('on');;
    }

    function proSelOptionCustom(){
        var optionList = "";
        var selectOption = $('.csHome select option');
        var optCnt = selectOption.length;
        //var flagWrap = [];

    //    for(var i=0; i < optCnt; i++){
    //         if(selectOption.eq(i).data('flag')) flagWrap[i] = '<div class="flag-wrap bg-type"><span class="flag">'+selectOption.eq(i).data('flag')+'</span></div>';
    //         else flagWrap[i] = "";
    //    }

       for(var i=0; i < optCnt; i++){
        optionList +=
        '<li><a href="#1" data-value="" data-text="'+selectOption.eq(i).text()+'" title="전체 카테고리 선택">'+
            '<span class="ui-select-text">'+selectOption.eq(i).text()+'</span>'+
            //'<div class="info">'+flagWrap[i]+'<span class="code">'+selectOption.eq(i).data('code')+'</span></div>';
            '<div class="info"><span class="code">'+selectOption.eq(i).data('code')+'</span></div>';
        '</a></li>';
       }
        var selectWrap = $('.sort-select-wrap.csHome .ui-selectbox-wrap');
        selectWrap.find(".ui-select-scrollarea ul").empty().append(optionList);
        selectWrap.find('.ui-select-scrollarea').mCustomScrollbar();
    }
    
    proSelButtomCustom();
    proSelOptionCustom();

    $('.csHome select').on('change', function(e) {
        proSelButtomCustom();
    });

    
});

// BTOCSITE-50782 고객지원 홈 ] (PC 버전) 하단 배너 노출 빈도 개선
// $(document).ready(function(){
//     $('.related-pages .item-list').slick({
//         dots: true,
//         infinite: true,
//         slidesToShow: 1,
//         autoplay: false
//     });
// });